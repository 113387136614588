import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

// import { Badge } from 'reactstrap';
import img1 from "../images/sube2.jpg";
import img2 from "../images/misube1.jpg";
import img3 from "../images/misube2.jpg";
import img4 from "../images/misube3.jpg";
import img5 from "../images/misube4.jpg";
// import img6 from "../images/subebanners.jpg";


const PortfolioPage = () => (
  <Layout>
    <SEO 
      title="Javier Suzuki | Design & Front-end Development Portfolio - Rock&Pop Blogs"
      keywords={[`javier`, `suzuki`, `web`, `ui`, `ux`, `design`, `front-end`, `development`, `wordpress`, `blog`]}
    />
    <div className="portfolio-header">
      <h1>SUBE</h1>
      <h5>Website, Web Applications & Marketing Campaigns</h5>
      <p>Design and front-end development of website and web applications for SUBE, the smart-card ticketing system used to pay for travel on public transport (buses, trains and subways) in the city of Buenos Aires, Argentina, working collaboratively with .NET developers, marketing directors and product owners.</p>
    </div>

    <div className="portfolio-content">
      <figure className="portfolio-sample">
        <figcaption className="figheading">SUBE Public Website</figcaption>
        <img src={img1} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">MySUBE Application</figcaption>
        <img src={img2} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        {/* <figcaption className="figheading">Radio Station Landing Page</figcaption> */}
        <img src={img3} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        {/* <figcaption className="figheading">Radio Station Landing Page</figcaption> */}
        <img src={img4} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        {/* <figcaption className="figheading">Radio Station Landing Page</figcaption> */}
        <img src={img5} alt=""/>
      </figure>
      {/* <figure className="portfolio-sample">
        <figcaption className="figheading">Banners for Marketing Campaigns</figcaption>
        <img src={img6} alt=""/>
      </figure> */}
    </div>

    <div className="portfolio-footer">
      {/* <a className="btn btn-primary" href="https://wushka.com">View Project</a> */}
    </div>

  </Layout>
)

export default PortfolioPage
